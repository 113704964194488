import { useEffect } from "react";
import { default as data } from './metadata.json';

function App() {
  const getAppStoreURL = (): string => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;
    let appStoreUrl: string;

    if (/android/i.test(userAgent)) {
      appStoreUrl =
        "https://play.google.com/store/apps/details?id=com.zenatapp";
    } else if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      !(window as any).MSStream
    ) {
      appStoreUrl = "https://apps.apple.com/il/app/zenat/id6467008834";
    } else {
      appStoreUrl = "https://zenat.app";
    }
    return appStoreUrl;
  };

  useEffect(() => {
    console.log(`App started, version: ${data.version}`);

    const redirectToDeepLink = (): void => {
      const searchParams = new URLSearchParams(window.location.search);
      const deepLinkingUrl = searchParams.get("originalUrl");

      if (!deepLinkingUrl) {
        window.location = "https://zenat.app" as any;
        return;
      }

      window.location = deepLinkingUrl as any;

      // Check if the app is opened after a short delay
      setTimeout(function () {
        // If the app is not opened, redirect to the app store
        window.location = getAppStoreURL() as any;
      }, 3000); // Adjust delay as needed
    };

    redirectToDeepLink();
  }, []);

  return <div></div>;
}

export default App;
